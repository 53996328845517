[data-theme="questico"] {
  --font-size: 16;
  --spacing: 8px;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --common-black: #000;
  --common-white: #fff;
  --primary-main: #00a2b8;
  --primary-dark: #007e8f;
  --primary-contrast-text: #ffffff;
  --secondary-main: #f76464;
  --secondary-dark: #e75e5f;
  --secondary-light: #fff2f2;
  --text-primary: #404041;
  --text-secondary: #72757e;
  --error-main: #d0021b;
  --success-main: #02d57c;
  --action-selected: #f2fbfc;
  --background-default: #f6f6f6;
  --grey-100: #f6f6f6;
  --grey-300: #d8d8d8;
  --grey-400: #bdbdbd;
  --grey-700: #404041;
  --grey--a200: #f1f0f0;
  --contextual-colors-negative: #ffffff;
  --contextual-colors-bg-main: #f6f6f6;
  --contextual-colors-neutral: #f1f0f0;
  --contextual-colors-ui-secondary: #afa8a9;
  --contextual-colors-text-body: #72757e;
  --contextual-colors-ui-primary: #404041;
  --contextual-colors-button-disabled: #d8d8d8;
  --contextual-colors-bg-secondary-accent: #f2fbfc;
  --contextual-colors-bg-primary-accent: #def0f3;
  --contextual-colors-bg-obstacle: #fff9f4;
  --contextual-colors-bg-error: #fff0f3;
  --contextual-colors-bg-voucher: #fff2f2;
  --contextual-colors-bg-footer: #2a2a2b;
  --contextual-colors-bg-status-available: #f2fdf8;
  --contextual-colors-brand-primary: #00a2b8;
  --contextual-colors-brand-primary-hover: #007e8f;
  --contextual-colors-brand-primary-inactive: #9edde5;
  --contextual-colors-brand-secondary: #f76464;
  --contextual-colors-brand-secondary-hover: #e75e5f;
  --contextual-colors-icons-text-error: #d0021b;
  --contextual-colors-icons-text-info: #0077cc;
  --contextual-colors-icons-success: #02d57c;
  --contextual-colors-icons-rating: #ffb400;
  --contextual-colors-attention: #f8b300;
  --contextual-colors-label-premium: linear-gradient(
    94.82deg,
    #ff943f 0.87%,
    #ffd269 100%
  );
  --contextual-colors-label-astro-tv: #8a83ff;
  --contextual-colors-label-new: #00c797;
}

[data-theme="viversum"] {
  --font-size: 16;
  --spacing: 8px;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --common-black: #000;
  --common-white: #fff;
  --primary-main: #0f5f5b;
  --primary-dark: #0b524e;
  --primary-contrast-text: #ffffff;
  --secondary-main: #ff7e9d;
  --secondary-dark: #db5b88;
  --secondary-light: #fff1f6;
  --text-primary: #38424f;
  --text-secondary: #6c6e77;
  --error-main: #d0021b;
  --success-main: #02d57c;
  --action-selected: #eefaf6;
  --background-default: #f6f6f6;
  --grey-100: #f6f6f6;
  --grey-300: #d8d8d8;
  --grey-400: #bdbdbd;
  --grey-700: #38424f;
  --grey--a200: #f1f0f0;
  --contextual-colors-negative: #ffffff;
  --contextual-colors-bg-main: #f6f6f6;
  --contextual-colors-neutral: #f1f0f0;
  --contextual-colors-ui-secondary: #a8aaaf;
  --contextual-colors-text-body: #6c6e77;
  --contextual-colors-ui-primary: #38424f;
  --contextual-colors-button-disabled: #d8d8d8;
  --contextual-colors-bg-secondary-accent: #eefaf6;
  --contextual-colors-bg-primary-accent: #dfecea;
  --contextual-colors-bg-obstacle: #fbf5e4;
  --contextual-colors-bg-error: #fff0f3;
  --contextual-colors-bg-voucher: #fff1f6;
  --contextual-colors-bg-footer: #2a2a2b;
  --contextual-colors-bg-status-available: #f4faf8;
  --contextual-colors-brand-primary: #0f5f5b;
  --contextual-colors-brand-primary-hover: #0b524e;
  --contextual-colors-brand-primary-inactive: #9abcba;
  --contextual-colors-brand-secondary: #ff7e9d;
  --contextual-colors-brand-secondary-hover: #db5b88;
  --contextual-colors-icons-text-error: #d0021b;
  --contextual-colors-icons-text-info: #0077cc;
  --contextual-colors-icons-success: #02d57c;
  --contextual-colors-icons-rating: #ffb400;
  --contextual-colors-attention: #f8b300;
  --contextual-colors-label-premium: linear-gradient(
    94.82deg,
    #ff943f 0.87%,
    #ffd269 100%
  );
  --contextual-colors-label-astro-tv: #8a83ff;
  --contextual-colors-label-new: #00c797;
}
